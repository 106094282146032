import React from 'react'
import {withPrefix} from 'gatsby'
import ParticlesBackground from '../components/layouts/particlesbackground'
import Layout from '../components/layouts/layout'
import HeadMeta from '../components/layouts/headmeta'

export default class StartPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeLeft: 5,
      redirecting: false
    }
    this.startCountdown = this.startCountdown.bind(this);
  }

  componentDidMount() {
    window.analytics.page('Static', 'Start Kit Activation')
    setInterval(this.startCountdown, 1000)
  }

  startCountdown() {
    if (this.state.timeLeft > 1) {
      this.setState({timeLeft: ( this.state.timeLeft-1 )})
    } else {
      this.setState({redirecting: true})
      window.location.href = "https://app.advanxhealth.com/kit/id"
    }

  }

  render() {
    const metadata = {
      title: "Activate Your Kit | Advanx Health",
      description: "Received your saliva collection kit? Activate it here now.",
      image: "https://www.advanxhealth.com/img/logo-horizontal-blue-wording.png",
      url: "https://www.advanxhealth.com/start"
    }

    const redirectText = this.state.redirecting ? "Redirecting now ..." : `Redirecting you in ${this.state.timeLeft} seconds ...`
    return (
      <Layout>
        <HeadMeta metadata={ metadata } />
        <section className="hero hero-home bg-gray">
          <ParticlesBackground />
          <div className="container">
            <div className="text-center mt-md-3">
              <h1>Do you have your kit in front of you?</h1>
              <img src={withPrefix('/img/kit.svg')} height="300px" alt="kit"/>
              <p className="text-black lead">{redirectText}</p>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
